.l-header {
  background-color: $white;
  @if $is-fixed == true {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 100%;
    height: $headerHeight;
    margin: 0 auto;
    padding: 0 0 0 30px;
    position: relative;
    z-index: 100;
    @include mq-max($changePoint) {
      display: block;
      height: $headerHeight-sp;
      padding: 0;
      position: fixed;
      top: 0;
      background: transparent;
    }
  }

  h1,.logo {
    margin-bottom: 0;
    margin-right: 20px;
    line-height: 0;
    display: inline-block;
    width: 200px; // 状況によって変更可
    @include mq-max($changePoint) {
      margin: 0;
      width: 120px;
    }
    img {
      width: 100%;
    }
  }
  .logo {
    img {
      opacity: 1;
      transition: opacity $transition;
    }
    &:hover {
      img {
        opacity: .7;
      }
    }
  }

  &__modules {
    position: relative;
    line-height: 0;
    @include mq-max($changePoint) {
      height: 100%;
      padding: 10px;
      background-color: $white;
      line-height: 0;
      display: flex;
      align-items: center;
    }
  }

  &__subNavArea {
    display: flex;
    align-items: center;
    @include mq-max($changePoint) {
      display: none;
    }
  }

  &__subNavItem {
    padding: 5px 15px;
    background-color: $bgColor;
    border-radius: 20px;
    margin: 0 3px;
    font-size: 14px;
    font-weight: 500;
    color: $textColor !important;
    transition: all $transition !important;

    &:hover {
      color: $white !important;
      background-color: $keyColor;
    }
  }

  //上部のボタンナビ
  &__subNav {
    display: flex;
  }

  //header上の段
  &__upperRow {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    @include mq-max($changePoint) {
      display: block;
      margin-bottom: 0;
    }
  }

  //メイン部分
  &__mainArea {
    flex-grow: 2;
    padding-right: 35px;
    @include mq-max($changePoint) {
      padding-right: 0;
    }
  }

  //右のCVボタンエリア
  &__cvBtnArea {
    display: flex;
  }

  &__cvBtn {
    font-weight: 500;
  }

  //電話番号
  &__telWrapper {
    font-size: 15px;
    padding-left: 35px;
    margin-left: 20px;
    position: relative;
    color: $keyColor;

    &::before {
      content: '';
      background-image: url('/inc/image/common/ico/ico_tel.png');
      background-repeat: no-repeat;
      background-size: contain;
      height: 30px;
      width: 30px;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &__tel {
    font-family: $roboto;
    font-weight: 600;
    font-size: 20px;
  }

  //コンバージョンボタン
  &__cvBtnArea {
    height: 100%;
    margin: 5px 0;
    @include mq-max($changePoint) {
      display: none;
    }

    &--sp {
      display: none;
      @include mq-max($changePoint) {
        display: flex;
        background-color: $white;
        justify-content: center;
      }
    }
  }
  &__cvBtn {
    height: 100%;
    position: relative;
    text-align: center;
    width: 110px;

    @include mq-max(lg) {
      width: 90px;
    }
    &::before {
      content: '';
      height: 90%;
      width: 1px;
      background-color: $gray-m;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &:first-child {
      &::before {
        @include mq-max($changePoint) {
          content: none;
        }
      }
    }
    & > a {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;
      padding-top: 15px;
      font-size: 13px;
      color: $textColor;
      @include mq-max(lg) {
        font-size: 10px;
      }
      @include mq-max($changePoint) {
        padding-top: 0;
      }
    }
    &Ico {
      width: 50px;
    }
  }

  //スクロール後の見た目
  &--scroll {
    @include mq-min($changePoint) {
      .l-header {
        &__inner {
          height: $headerHeight-scroll;
          align-items: start;
        }

        &__cvBtnIco {
          display: none;
        }

        &__cvBtnArea {
          margin: 0;
        }

        &__cvBtn {
          & > a {
            padding-top: 0;
            justify-content: center;
          }
        }

        &__upperRow {
          margin-bottom: 3px;

        }

        &__subNav {
          align-items: start;
        }

        &__subNavArea {
          align-items: start;
        }

        &__telWrapper {
          padding-top: 5px;
        }

        &__subNavItem {
          border-radius: 0 0 8px 8px;
        }

        &__modules {
          margin-top: 3px;
        }
      }

      h1,.logo {
        width: 120px;
      }

      .l-navigation {
        &__item {
          &.has-dropdown {
            &.isDropdownOpen {
              .l-dropDown {
                top: 50px;
              }
            }
          }
        }
      }
    }

  }
}

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  //  ハンバーガーメニュー

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
.c-toggleBtn {
  @include mq-max($changePoint) {
    display: block;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    width: 42px;
    height: 42px;
    cursor: pointer;
    z-index: 3;
    span {
      display: block;
      position: absolute;
      width: 30px;
      border-bottom: solid 3px $black;
      transition: .35s ease-in-out; //変化の速度を指定
      left: 6px;
      &:nth-child(1) {
        top: 9px;
      }
      &:nth-child(2) {
        top: 18px;
      }
      &:nth-child(3) {
        top: 27px;
      }
    }
    &.is-toggleActive {
      span{
        &:nth-child(1) {
          top: 18px;
          left: 6px;
          transform: rotate(-45deg);
        }
        &:nth-child(2),&:nth-child(3) {
          top: 18px;
          transform: rotate(45deg);
        }
      }
    }
  }
}
