.l-f {
  background-color:#c4c4c4;
  &__inner {
    max-width: $contentWidth;
    width: 100%;
    margin: 0 auto;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    @include mq-max(content) {
      padding: 50px 20px;
    }
    @include mq-max(xs) {
      flex-wrap: wrap;
      margin-bottom: -30px;
    }
  }
  &__info {
    display: block;
  }
  &__logo {
    > img {
      width: auto;
    }
  }
  &__nav {
    display: flex;
  }
  &__links {
      margin-left: 50px;
      @include mq-max(xs) {
        margin-left: 0;
        margin-bottom: 30px;
      }
      &:first-child {
        margin-left: 0;
      }
      @include mq-max(xs) {
        width: 50%;
      }
      & > li {
        a {
          display: block;
          padding: 2px 0;
        }
      }
    }

  //Copyright
  &__copyright {
    text-align: center;
    display: block;
    color: $white;
    background-color: $black;
    padding: 10px 0;
  }
}
