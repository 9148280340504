.l-navigation {
//  background-color: $white;
  height: 100%;
  @include mq-max($changePoint) {
    display: none;
    height: auto;
    //用途不明　不要ならそのうち削除
    // html.open &.active > ul {
    //   overflow-y: scroll;
    //   height: calc(100vh - #{$headerHeight-sp});//画面高さ - fixedヘッダーの高さ
    //   padding-bottom: 120px;
    // }
  }
  //用途不明　不要ならそのうち削除
  // &.active {
  //   transform: translateY(0%);
  // }

  //ハンバーガーメニューオープン時に追加されるクラス
  &.is-navOpen {}

  &__inner {
    padding: 0;
    display: flex;
    height: 100%;//menu.jsで指定した値が優先される
    @include mq-max($changePoint) {
      display: block;
      height: calc(100vh - #{$headerHeight-sp}); //画面高さ - fixedヘッダーの高さ
      overflow-y: scroll;
    }
  }
  &__item {
    position: relative;
    flex-grow: 1;
    // display: flex;
    // align-items: center;
    height: 100%;
    border-left: 1px solid $bdrColor;
    text-align: center;
    &:first-child {
      border-left: none;
    }
    @include mq-max($changePoint) {
      display: block;
      height: auto;
      margin-right: 0;
      border-top: 1px solid $gray-m;
      border-left: none;
      text-align: left;
      &:last-child{
        border-bottom: 1px solid $gray;
      }
    }
    a {
      @include mq-max($changePoint) {
        background-color: $white;
      }
    }
    &>a {
      text-decoration: none;
      color: $textColor;
      font-size: 15px;
      display: inline-block;
      font-weight: 500;
      padding: 2px 15px;
      @include mq-max(lg) {
        font-size: 13px;
        padding: 2px 10px;
      }

      @include mq-max($changePoint) {
        display: block;
        padding: 20px 30px;
        position: relative;
        font-size: 15px;
      }

      &::after {
        @include mq-max($changePoint) {
          @include fa('f138');
          position: absolute;
          right: 16px;
        }
      }
      &:hover {
        color: $hoverColor;
      }
    }

    //下層メニュー
    &.has-dropdown {
      &>a {
        position: relative;
        &::after {
          @include fa('f107');
          // right: -20px;
          position: absolute;
          right: 0;
          color: $gray-m;


          @include mq-max($changePoint) {
            @include fa('f067');
            position: absolute;
            top: 50%;
            right: 16px;
            transform: translateY(-50%);
            color: $textColor;

          }
        }
        &.has-dropdown {
          &::after {
            @include mq-max($changePoint) {
              content: '\f068';
            }
          }
        }
      }

      .l-dropDown {
        position: absolute;
        // top: $headerHeight - 30px; // headerの高さ - 30px
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;
        transition: all .5s ease;
        z-index: -1;
        overflow: hidden;
        border-radius: $border-radius;
        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
          left: 0;
        }
        @include mq-max($changePoint) {
          position: relative;
          transition: none;
          top: auto;
          opacity: 1;
          visibility: visible;
          z-index: 1;
          border-radius: 0;
        }
        li {
          border-bottom: 1px solid $gray;
          @include mq-max($changePoint) {
            border-bottom: none;
          }
          &:last-child {
            border: none;
          }
          a {
            background-color: $white;
            padding: 10px 15px;
            white-space: nowrap;
            display: block;
            text-decoration: none;
            color: $textColor;
            width: 100%;
            transition: all $transition !important;
            @include mq-max($changePoint) {
              line-height: 0;
              height: 0;
              padding: 0;
              opacity: 0;
              transition: height $transition,
                          opacity 0.4s,
                          padding $transition,
                          line-height $transition;
            }

            &::after {
              @include mq-max($changePoint) {
                @include fa('f138');
                position: absolute;
                right: 16px;
              }
            }

            &:hover {
              background-color: $bgColor;
            }
          }
        }
      }

      // クリック時
      &.isDropdownOpen {
        &>a {
          &::after {
            @include fa('f106');

            @include mq-max($changePoint) {
              @include fa('f068');
            }
          }
        }
        .l-dropDown {
          // top: $headerHeight; // headerの高さ
          top: 60px;
          opacity: 1;
          visibility: visible;
          @include mq-max($changePoint) {
            top: auto;
            opacity: 1;
            display: block;
            background-color: $bgColor;
          }
          &>li {
            border-bottom: 1px solid $gray-m;
            @include mq-max($changePoint) {
              margin-left: 40px;
            }
            &:first-child {
              border-top: 1px solid $gray-m;
            }
            &:last-child {
              border-bottom: none;
            }
          }
          a {
            transition: color $transition,
                        height $transition;
            &:hover {
              color: $keyColor;
            }
            @include mq-max($changePoint) {
              opacity: 1;
              line-height: 1.5;
              height: 54px;
              padding: 15px 25px;
              overflow: hidden;
              background-color: $bgColor;
            }
          }
        }
      }

    }
  }
}
